.your-code-001234 {
  font-family: "IBM Plex Mono";
  width: 100%;
  margin: 0;
  line-height: normal;

  position: relative;
  background-color: #ffc179;
  border: 20px solid #ffc179;
  /* height: 1105px; */
  /* padding: 32px; */
  text-align: left;
  font-size: 11px;
  color: #000;
  @media (max-width: 610px) {
    * {
      font-size: 12px;
    }
  }
}

.invoice-serial,
.eg-10001-badr,
.fimtosoft,
.invoice {
  position: relative;
  line-height: 140%;
}

.invoice {
  display: inline-block;
  width: 70px;
  flex-shrink: 0;
}

.invoice-serial {
  font-size: 16px !important;
}

.eg-10001-badr {
  font-size: 16px;
}

.invoice-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}

.fimtosoft-parent,
.frame-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-parent {
  gap: 6px;
  font-size: 14px;
}

.fimtosoft-parent {
  gap: 8px;
}

.fimtosoft-parent:first-child {
  width: -webkit-fill-available;
}

// .image-1-icon {
//   width: 88px;
//   height: 55px;
//   object-fit: cover;
// }

.logo {
  aspect-ratio: 1/1;
  width: 25%;

  border-radius: 50%;
  background: #ff890047;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 75%;
    // aspect-ratio: 1/1;
    object-fit: cover;
  }
  &[data-aquarium-ticket-logo="true"] {
    width: 30%;
  }
}

.aquarium-logo {
  aspect-ratio: 1/1;
  width: 35%;

  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 75%;
    object-fit: cover;
  }
}
.header {
  width: 85%;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 25px;
}

.frame-child {
  position: relative;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.ellipse-parent {
  position: relative;
  background-color: transparent !important;
  border-bottom: 2px dashed #ffc179;
  width: 100%;
}

.ellipse-parent::after,
.ellipse-parent::before {
  position: absolute;
  top: -14px;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #ffc179;
  content: "";
}

[data-aquarium-ticket="true"],
[data-aquarium-ticket="true"]::after,
[data-aquarium-ticket="true"]::before {
  background-color: #337ab7;
  border-color: #337ab7;
  border-bottom-color: #337ab7 !important;
}

.ellipse-parent::after {
  right: -16px;
}

.ellipse-parent::before {
  left: -16px;
}

.b {
  position: relative;
  line-height: 140%;
  display: inline-block;
  width: 20px;
  flex-shrink: 0;
}

.description-optional {
  color: #000000b3;
}

.item-description-optional-container {
  position: relative;
  line-height: 140%;
  display: inline-block;
  width: 45%;
  flex-shrink: 0;
}

.usd {
  color: rgba(0, 0, 0, 0.48);
}

.paid,
.rest,
.total-usd {
  flex: 1;
  position: relative;
  line-height: 140%;
}

.rest {
  text-align: right;
}

.table-header {
  align-self: stretch;
  border-bottom: 1px solid #00000014;
  display: flex;
  flex-direction: row;
  padding: 0 0 10px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14px;
  color: #000;
  font-size: 14px;
}

.div,
.online-video {
  position: relative;
  line-height: 140%;
  font-weight: 500;
}

.div {
  display: inline-block;
  width: 20px;
  flex-shrink: 0;
}

.online-video {
  align-self: stretch;
}

.write-a-description {
  position: relative;
  line-height: 133%;
  color: #000000b3;
  display: inline-block;
  width: 216px;
}

.task {
  width: 45%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}

.div1,
.div3 {
  flex: 1;
  position: relative;
  line-height: 140%;
  font-weight: 500;
}

.div3 {
  text-align: right;
}

.task-name {
  align-self: stretch;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14px;
  font-size: 16px;
}

.task-name:last-child {
  border-bottom: none;
}

.task-details {
  position: relative;
  width: 85%;
  /* height: 361px; */
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 16px;
  color: #000000e0;
}

.client-details {
  position: relative;
  line-height: 140%;
  text-transform: uppercase;
  font-size: 14px;
}

.name {
  font-weight: 500;
}

.john-smith,
.name {
  position: relative;
}

.frame-group {
  /* flex-direction: row; */
  /* gap: 16px; */
  font-size: 16px;
  color: #000000e0;

  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 8px;
}

.client-details-parent,
.frame-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.client-details-parent {
  width: 100%;
  flex-shrink: 0;
  flex-direction: column;
  gap: 8px;
}

.frame-container {
  flex-direction: row;
  gap: 16px;
}

.footer,
.footer-inner {
  flex-shrink: 0;
  display: flex;
}

.footer-inner {
  width: 250px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 10px;
  color: #000000e0;
}

.footer {
  position: relative;
  background-color: #ff000012;
  border-top: 2px solid #ff000029;
  box-sizing: border-box;
  width: 100%;
  flex-direction: row;
  padding: 10px 7.5%;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 8px;
  color: rgba(0, 0, 0, 0.64);
}

.paid-stamp {
  width: 120px;
  height: 120px;
  position: absolute;
  top: -25px;
  right: 30px;
  opacity: 0.6;
  transform: rotate(20deg);
}

.edit-stamp,
.today-delivery {
  position: absolute;
  font-size: 40px !important;
  color: red;
  border: 3px solid red;
  padding-inline: 20px;
  border-radius: 9px;
  opacity: 0.5;
  left: 90px;
  top: -25px;
  transform: rotate(-20deg);
}
.today-delivery {
  bottom: -25px;
  top: unset;
  left: unset;
  right: 25px;
  font-size: 16px !important;
  width: min-content;
}
.download-your-photo {
  position: relative;
  line-height: 27.5px;
  display: inline-block;
  flex-shrink: 0;
}

.album-link {
  position: relative;
  text-decoration: underline;
  letter-spacing: 0.04em;
  line-height: 27.5px;
  font-weight: 700;
  font-size: 11px;
  color: #ff8900;
  word-break: break-all;
}

.download-your-photo-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.qrcode-img {
  position: relative;
  width: 120px;
  height: 110px;
  flex-shrink: 0;
  object-fit: cover;
}

.frame-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.border-bottom1 {
  position: absolute;
  margin: 0 !important;
  width: 100.22%;
  right: -0.21%;
  bottom: -0.25px;
  left: -0.01%;
  border-top: 0.5px solid #d0d0d0;
  box-sizing: border-box;
  height: 0.5px;
  flex-shrink: 0;
  z-index: 0;
}

.we-will-meet {
  position: relative;
  letter-spacing: -0.05em;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: #337ab7;
}

.amount1 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  z-index: 1;
}

.amount1,
.amount2,
.your-photos-will {
  display: flex;
  justify-content: center;
}

.your-photos-will {
  position: relative;
  letter-spacing: -0.05em;
  font-weight: 500;
  align-items: flex-end;
  font-weight: bold;
}

.amount2 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  z-index: 2;
}

.text-container1 {
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: 24px;
  text-align: center;
  font-size: 14px;
  color: #000;
}

.footer1 {
  border-top: 2px solid transparent;
  overflow: hidden;

  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: 1fr;
  font-size: 14px;

  /* flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; */
}

.footer1 > :only-child {
  grid-column: 1/-1;
}

.dont-hesitate-to {
  position: relative;
  line-height: 140%;
  color: #000;
  display: inline-block;
  width: 45%;
  flex-shrink: 0;
}

.table-header1 {
  align-self: stretch;
  border-top: 1px solid #00000014;
  display: flex;
  flex-direction: row;
  padding: 4px 0;
  align-items: center;
  justify-content: center;
  gap: 14px;
  color: #000000b3;
}

.footer-parent,
.header-parent,
.your-code-001234 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-parent {
  width: 85%;
  flex-shrink: 0;
  justify-content: flex-start;
  gap: 16px;
  color: #2e2e2e;
}

.header-parent,
.your-code-001234 {
  box-sizing: border-box;
  justify-content: center;
}

.header-parent {
  background-color: #fff;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  padding: 16px 0 8px;
  gap: 20px;
  border-radius: 16px;
}

$mainFontFamily: "Poppins",sans-serif;
$headingFontFamily: "Poppins",sans-serif;

$primary  :#0F6DAE;
$primary-dark  :#0c4d7a;
$secondary:#FF8900;
$light : white;
$off-light : #fafafa;
$dark : black;
$body-dark:#6b6b84;
$light-grey:#eee;
$dark-grey:#2C2C2C;
$footer-txt-color:#6A6975;
$bg-color:#2C2C2C;
$trans-250: 0.25s all ease;
.layoutContainer {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  @media (max-width: 1000px) {
    height: fit-content;
    aspect-ratio: 3/2;
  }
}

.whatsappBtn {
  position: fixed;
  bottom: 3%;
  right: 3%;
  img {
    width: 40px;
    height: 40px;
    @media (max-width: 1000px) {
      width: 25px;
      height: 25px;
    }
  }
}

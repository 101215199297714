@import "../../styles/varibles.scss";

.home {
  // background: linear-gradient(-45deg, #ffffff, #bacbdf, #6abddb, #23d5ab);
  // background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background: $primary;
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  width: 23rem;
  height: 50vh;
  min-height: 20rem;
  margin-bottom: 1rem;
  z-index: 3;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  div {
    color: white;
    font-size: 1.1rem;
    font-weight: 600;
  }
  span {
    color: #eb8f39;
  }
  button{
    padding: 0.5rem 4rem;
  }
  * + * {
    margin-top: 1.5rem;
  }
  @media (max-width: 610px) {
    margin-top: 1rem;
    height: 60%;
    z-index: 1;
  }
}

@import "../styles/varibles.scss";
@import "~antd/dist/antd.css";

html {
  font-size: 16px;
  font-family: $mainFontFamily;
  scroll-behavior: smooth;

  @media (max-width: 991px) {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

body {
  margin: 0rem;
  color: white;
}

.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.main {
  flex-grow: 1;
}

.main-with-padding {
  flex-grow: 1;
  padding-top: 85px;
}
.black-background {
  background-image: url("background.svg");
}

.container {
  width: 1280px;
  margin: 0% auto;
  overflow: hidden;
  @media (max-width: 1800px) {
    width: 96%;
    margin: 0px 2%;
  }
}

// global styling
h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: $headingFontFamily;
}
p,
input,
textarea {
  font-family: $mainFontFamily;
  color: $body-dark;
}

input,
textarea,
button {
  &:focus {
    outline: none;
  }
}
a {
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
  }
}
.input {
  width: 75%;
  border: 1px solid #e1e1e1;
  padding: 0.6rem 0.9rem;
  caret-color: #959595;
  color: #444444;
  border-radius: 1.5rem;
  font-size: 1rem;
  transition: all ease-in-out 0.2s;
  resize: none;
  &::placeholder {
    font-size: 1rem;
    color: #959595;
  }

  &:focus {
    outline: none;
    border: 1px solid #bbbbbb;
  }
}
.button {
  color: white;
  padding: 0.5rem;
  background-color: $secondary;
  border: none;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 1.5rem;
  transition: all ease-in-out 0.2s;
  text-align: center;
  cursor: pointer;
  height: fit-content;

  &:disabled {
    pointer-events: none;
  }

  &:hover {
    background-color: #fc9b2c;
    box-shadow: 1px 1px 16px rgb(171 166 166 / 60%);
    animation: none;
  }

  &:focus {
    outline: none;
  }

  &:active {
    background-color: #eca867;
  }
}
.animated-button {
  animation: ring 3s infinite;
  @keyframes ring {
    0% {
      box-shadow: 1px 1px 16px rgb(171 166 166 / 100%);
      scale: 1;
      opacity: 1;
    }
    50% {
      box-shadow: 1px 1px 16px rgb(171 166 166 / 30%);
      scale: 1.06;
      opacity: 0.7;
    }
    100% {
      box-shadow: 1px 1px 16px rgb(171 166 166 / 100%);
      scale: 1;
      opacity: 1;
    }
  }
}
.secondary-button {
  color: rgb(56, 56, 56);
  padding: 0.5rem;
  background-color: transparent;
  border: 1px solid $secondary;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 1.5rem;
  transition: all ease-in-out 0.2s;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #fc9b2c;
    box-shadow: 1px 1px 16px rgb(171 166 166 / 60%);
  }

  &:focus {
    outline: none;
  }

  &:active {
    background-color: #eca867;
  }
}
.unvalid {
  border: 4px solid #d60000 !important;
}

.imgCont {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 300px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.form-container .ant-select-selector {
  height: 100% !important;
  border-radius: 2rem !important;
  border-color: rgb(158, 158, 158) !important;
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    text-align: center !important;
    border-color: #9e9e9e !important;
    font-weight: 600 !important;
    font-size: 18px !important;
  }
}
.ant-picker-large {
  border-color: #9e9e9e;
  border-radius: 2rem;
  direction: rtl;
}
.ant-input-group .ant-select-selector {
  height: 100% !important;
  border-radius: 2rem 0 0 2rem !important;
  border-color: rgb(158, 158, 158) !important;
}

.dx-gridbase-container {
  padding: 1rem;
  border-radius: 1rem;
}

.dx-overlay-wrapper.dx-popup-wrapper.dx-overlay-shader {
  position: fixed !important;
  transform: none !important;
}

.dx-master-detail-cell {
  .dx-box-flex.dx-box.dx-widget.dx-visibility-change-handler.dx-collection {
    flex-direction: row !important;
    flex-wrap: wrap;
    align-items: center;
  }
  .dx-item.dx-box-item {
    max-width: 100%;
    width: 25rem;
  }
}

.PhoneInputCountry {
  margin-left: 0.3rem;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(100px);
  }
}

svg #wheel {
  animation: scroll ease 1.5s infinite;
}

.printContainer {
  // padding-inline: 25px;
  // max-height: 100px;
  * {
    font-size: 20px;
  }

  .dont-hesitate-to {
    font-size: 16px;
  }

  overflow: hidden;
  color: rgb(94, 55, 109);
  .your-code-001234 {
    height: 1122px;

    .header-parent {
      justify-content: space-around;
    }
    .footer1 {
      display: block;
    }

    .hidden-print {
      display: none;
    }
  }
  td {
    text-align: center;
  }
}

.smallPrintContainer {
  .your-code-001234 {
    height: 559px;

    * {
      font-size: 10px;
      // font-weight: 500 !important;
      // padding-top: 10px !important;
      // word-break: break-all;
    }

    .dont-hesitate-to {
      font-size: 8px;
    }

    .item-description-optional-container,
    .task {
      width: 42%;
    }

    // .logo {
    //   width: 100px;
    //   height: 75px;
    //   .image-1-icon {
    //     height: 40px;
    //     width: 60px;
    //   }
    // }

    h3 {
      font-size: 10px !important;
      font-weight: 500 !important;
    }

    .ellipse-parent:last-of-type {
      display: none;
    }

    .header-parent {
      justify-content: space-around;
      gap: 10px;
    }

    .border-bottom1 {
      display: none !important;
    }

    .footer1 {
      display: block;
    }

    .hidden-print {
      display: none;
    }
  }

  table {
    margin-block: 5px !important;
    th {
      font-size: 9px !important;
      font-weight: 400;
      padding: 4px !important;
    }
  }
}

.form-container .ant-select-selector .ant-select-selection-placeholder,
.form-container .ant-select-selector .ant-select-selection-item {
  border-radius: 2rem;
}

.ant-input-search-button {
  padding-inline: 1rem !important;
  padding-block: 0 !important;
  margin-block: 0 !important;
  height: 38.9px;
}

.ant-picker-input > input[disabled] {
  color: black;
}

.tablePrintContainer {
  padding: 30px;
  // height: 100vh;
  // display: flex;
  // flex-direction: column;
  // height: 100vh;
  border-bottom: 20px solid black;
  &::before {
    content: " ";
    background: url("../assets/LOGO.png");
    padding: 30px;
    background-size: 65px 40px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 65px;
    height: 40px;
  }

  * {
    font-size: 10px;
    color: black;
    height: max-content;
  }

  tr {
    page-break-inside: avoid;
  }

  .dx-data-row {
    [aria-colindex="6"] {
      background-color: #eca867;
    }
  }

  .dx-header-row {
    color: black;
    background-color: #eca867;
  }

  .dx-datagrid-header-panel {
    display: none !important;
  }

  .print-footer {
    position: fixed;
    bottom: 0;
    width: calc(100% - 60px);
    justify-content: space-between;
    align-items: center;
    display: flex;
    background-color: transparent;
    z-index: -1;
  }
}

.send-receipt-msg {
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  font-size: 18px;
  text-align: center;
  line-height: 18px;
  margin: 0 3px;
  cursor: pointer;
}
.ant-upload.ant-upload-select-picture-card {
  min-width: 200px;
  min-height: 200px;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  width: 100%;
  height: 100%;
  button {
    min-height: 200px;
  }
}

.receipt-popup-container {
  @media (max-width: 768px) {
    .dx-popup-content {
      padding: 0;
      padding-bottom: 10px;
    }
  }
  .dx-popup-bottom.dx-toolbar {
    @media (max-width: 768px) {
      height: 95px !important ;
      padding: 0 20px;
    }
    .dx-toolbar-after {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .dx-toolbar-button {
        margin-bottom: 10px;
      }
    }
  }
}

.approve-expenses-modal {
  .dx-toolbar-button:first-child {
    flex-basis: 100%;
    margin-bottom: 20px !important;
  }
  .dx-toolbar-button:last-child {
    flex-basis: 100%;
    margin-top: 25px;
    .dx-toolbar-item-content {
      display: flex;
      justify-content: end;
    }
  }
  .dx-popup-bottom.dx-toolbar {
    height: 175px !important ;
    padding: 0 20px;
  }
  .dx-toolbar-before {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .dx-toolbar-button {
      margin-bottom: 10px;
    }
  }
}

@import "../styles/varibles.scss";

.header {
  font-family: $headingFontFamily;
  background-color: transparent;
  padding: 1.5rem 0;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 85px;
  overflow: hidden;

  .header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      display: flex;
      align-items: center;
    }
  }

  a {
    color: $light;
    text-decoration: none;
    margin: 0 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    @media (max-width: 1000px) {
      margin: 0 0.7rem;
    }
    &:hover {
      color: inherit;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
  [aria-label="logo"] {
    svg {
      width: 100px;
      height: 60px;
      @media (max-width: 1000px) {
        width: 65px;
        height: 40px;
      }
    }
  }
}

.footer {
  .mainFooter {
    background-color: $bg-color;
    color: $light;
    padding: 1rem;
    svg {
      -webkit-filter: invert(100%); /* Safari/Chrome */
      filter: invert(100%);
    }
  }

  .footerContainer {
    display: grid;
    justify-content: center;
    grid-template-columns: 350px repeat(2, 1fr);
    @media (max-width: 1000px) {
      grid-template-columns: 100%;
      grid-template-rows: repeat(3, 1fr);
      gap: 2rem;
    }
  }

  .contacts {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    h4 {
      color: $light;
    }
    @media (max-width: 1000px) {
      align-items: flex-start;
    }
  }
  .we {
    display: flex;
    justify-content: center;
  }

  .contactLink {
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      justify-content: center;
      display: inline-flex;
    }

    .hideTitle {
      @media (max-width: 610px) {
        display: none;
      }
    }
  }

  .address {
    iframe {
      width: 100%;
      height: auto;
      border: none;
      filter: hue-rotate(285deg);
    }
  }

  .contacts {
    .whatsapp {
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        display: inline-flex;
        justify-content: center;
      }

      svg {
        font-size: 1.6rem;
        transition: $trans-250;
        @media (max-width: 768px) {
          font-size: 2.2rem;
        }
      }

      &:hover {
        span {
          text-decoration: underline;
        }
        svg {
          color: #00e676;
        }
      }
    }
  }

  .social {
    a {
      svg {
        font-size: 1.6rem;
      }
    }
  }
  .contactList {
    display: flex;
    justify-content: center;
    flex-direction: column;
    a {
      display: grid;
      grid-template-columns: 30px 0 1fr;
      margin-right: 1rem;
      margin-bottom: 1rem;
      align-items: center;
    }
  }
}

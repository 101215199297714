.partnerBody {
  width: 100%;
  height: 100%;
  background-color: rgba(31, 31, 31, 0.5);
  padding-inline: 15%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  @media (max-width: 1000px) {
    padding: 0;
  }
}

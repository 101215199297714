@import "../../styles/varibles.scss";

.home {
  background: #2c2c2c;
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  min-width: 300px;
  min-height: 375px;
  a {
    width: 100%;
  }
  div {
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
  }
  * + * {
    margin-top: 1.5rem;
  }

  input {
    width: 100%;
    background-color: $secondary;
    border: none;
    color: $light;
    box-sizing: border-box;

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }
  button {
    padding-inline: 2rem;
  }
  @media (max-width: 610px) {
    margin-top: 1rem;
    height: 60%;
    z-index: 1;
  }
}

.homeCont {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 1000px) {
    justify-content: center;
  }
}

.backBtn {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  align-self: flex-start;
}

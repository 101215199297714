@import "../../styles/varibles.scss";

.headerContainer {
  width: 100%;
  height: 100%;
  background-color: rgba(31, 31, 31, 0.5);
  padding-inline: 15%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  h1 {
    line-height: 5rem;
    font-size: 75px;
    word-spacing: 101vw;
    font-style: italic;
    color: $light;
    font-weight: bold;
    @media (max-width: 1000px) {
      font-size: 5vw;
      line-height: 7vw;
    }
  }
  button {
    width: 17rem;
    height: fit-content;
  }
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 25% 0;
  width: 100%;
  box-sizing: border-box;
  svg:nth-child(2) {
    width: 100%;
    height: 100%;
    margin-top: 3rem;
  }
  img {
    width: 100%;
    height: 100%;
    margin-top: 3rem;
  }
  @media (max-width: 1000px) {
    padding: 3rem 5% 0 !important;
  }
}

.projectsSection {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 3rem;
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2.5rem;
  }
}

.projectContainer {
  width: 26rem;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  svg {
    width: 12rem;
    height: 6rem;
  }
}

.partnerSelectContainer {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  position: relative;
}

.dropdownContainer {
  text-align: center;
  background-color: $dark-grey;
  border-radius: 1rem;
  overflow: hidden;
  position: absolute;
  left: 18rem;
  width: 17rem;
  @media (max-width: 1000px) {
    left: 0;
    top: 4rem;
  }
  a {
    padding-inline: 2rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    font-weight: 500;
    &:hover {
      background-color: rgba(218, 216, 216, 0.32);
      cursor: pointer;
    }
  }
}

.scrollDown {
  position: absolute;
  bottom: 10px;
  left: 47%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1000px) {
    display: none;
  }
  h2 {
    color: white;
  }
  svg {
    height: 4vw;
  }
}
